import { persisted } from 'svelte-persisted-store';
import { derived } from 'svelte/store';

export interface User {
	id: number;
	username: string;
	avatar: string;
}

export const user = persisted('user', null as User | null);

export const authenticated = derived(
	user,
	($user) => $user?.username !== null && $user?.username !== undefined && $user?.username.length > 0
);
